import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { withFirebase, storage } from "../../firebase";
import Labels from ".";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import { ref } from "firebase/storage";

const LabelsContainer = ({ firebase }) => {
	const [preloader, setPreloader] = useState(false);
	const [labels, setLabels] = useState(null);
	const [keyShop, setKeyShop] = useState(null);
	const [currentCity, setCurrentCity] = useState(null);
	const [currentBrand, setCurrentBrand] = useState(null);

	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");

	const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));
	const isNewAdmin = adminPermission?.newAdmin;

	const location = useLocation();

	const refIsNewAdmin = isNewAdmin
		? `/web/settings/city/${currentCity}/brands/${currentBrand}/category/productsTags `
		: `/web/settings/city/${currentCity}/category/productsTags`;

	useEffect(() => {
		setKeyShop(location.pathname);
		getFieldsLabels();
	}, [location.pathname]); // Обновляем данные при изменении пути

	const getFieldsLabels = () => {
		let urlParts = location.pathname.split("/");
		let url = urlParts.find((item) => item === "0" || parseInt(item));

		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		const refIsNewAdmin = isNewAdmin
			? `web/settings/city/${currentCity}/brands/${currentBrand}/category/productsTags`
			: `web/settings/city/${currentCity}/category/productsTags`;

		const refPath = `${isDebug ? "debug" : ""}${refIsNewAdmin}`;

		// Получаем обновленные данные
		firebase.bd
			.ref(`${refPath}/tags`)
			.once("value")
			.then((snapshot) => {
				const tagsData = snapshot.val();

				setLabels(tagsData || []); // Если данных нет, устанавливаем пустой массив
				setKeyShop(url);
				setCurrentCity(currentCity);
				setCurrentBrand(currentBrand);
			})
			.catch((error) => {
				console.error("Ошибка при получении данных:", error);
			});
	};

	const changeChecker = useCallback(
		(value, id) => {
			console.log("id (changeChecker) --- >", id);
			const isDebug = JSON.parse(localStorage.getItem("isDebug"));
			setPreloader(true);

			firebase.bd
				.ref(`${isDebug ? "debug" : ""}${refIsNewAdmin}/tags/${id}`)
				.update({ isActive: value })
				.then(() => {
					setPreloader(false);
					getFieldsLabels();
				});
		},
		[currentCity, currentBrand, firebase.bd]
	);

	const deleteLabel = useCallback(
		(id) => {
			console.log("=== Delete label ===", id);
			const isDebug = JSON.parse(localStorage.getItem("isDebug"));
			setPreloader(true);

			let img = labels.find((item, index) => {
				return index === +id;
			});

			const updatedLabels = labels.filter((_, index) => index !== +id);

			setLabels(updatedLabels);

			firebase.bd
				.ref(`${isDebug ? "debug" : ""}${refIsNewAdmin}/tags`)
				.set(updatedLabels)
				.then(() => {
					if (img) {
						handleDeleteImg(img.image);
						isDeleteProductTags(updatedLabels);
						getFieldsLabels();
					}
					setPreloader(false);
					getFieldsLabels();
				})
				.catch((error) => {
					console.error("Error deleting label:", error);
					setPreloader(false);
				});
		},

		[currentCity, currentBrand, firebase.bd, getFieldsLabels]
	);

	const isDeleteProductTags = (data) => {
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		if (Object.keys(data).length < 1) {
			firebase.bd
				.ref(`${isDebug ? "debug" : ""}${refIsNewAdmin}`)
				.remove()
				.then(() => {
					console.log("productTags успешно удален.");
					setPreloader(false);
					getFieldsLabels();
				})
				.catch((error) => {
					console.error("Ошибка при удалении productTags:", error);
					setPreloader(false);
				});
		}
	};
	const createLabel = useCallback((data) => {
		console.log("=== Create label data ===", data);
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		setPreloader(true);

		console.log("currentCity", currentCity);
		console.log("currentBrand", currentBrand);

		const refPath = `${isDebug ? "debug" : ""}${refIsNewAdmin}`;

		// Проверяем, существует ли узел productTags и создаем его, если его нет
		firebase.bd
			.ref(refPath)
			.once("value")
			.then((snapshot) => {
				const productTagsData = snapshot.val();

				if (!productTagsData) {
					console.log("product tags");
					// Если productTags не существует, создаем его и добавляем пустой tags
					firebase.bd.ref(refPath).set({ isTagsAvailable: true });
				}

				firebase.bd
					.ref(`${isDebug ? "debug" : ""}${refIsNewAdmin}/tags`)
					.once("value")
					.then((snapshot) => {
						const existingTags = snapshot.val() || [];
						// Убедитесь, что в массиве нет `undefined` элементов
						const filteredTags = existingTags.filter(
							(tag) => tag !== undefined
						);
						// Добавляем новый тег в конец массива
						const updatedTags = [...filteredTags, data];

						firebase.bd
							.ref(`${isDebug ? "debug" : ""}${refIsNewAdmin}/tags`)
							.set(updatedTags)
							.then(() => {
								setPreloader(false);
								// window.location.reload();
								getFieldsLabels();
							})
							.catch((error) => {
								console.error("Error adding label:", error);
								setPreloader(false);
							});
					})
					.catch((error) => {
						console.error("Error fetching tags:", error);
						setPreloader(false);
					});
			});
	});

	const updateLabel = useCallback(
		(labelId, updatedData) => {
			console.log("=== Update label data ===", labelId, updatedData);
			const isDebug = JSON.parse(localStorage.getItem("isDebug"));
			setPreloader(true);

			// Обновляем данные для конкретного лейбла по ключу
			firebase.bd
				.ref(`${isDebug ? "debug" : ""}${refIsNewAdmin}/tags/${labelId}`)
				.update(updatedData)
				.then(() => {
					setPreloader(false);
					getFieldsLabels();
				})
				.catch((error) => {
					console.error("Error updating label:", error);
					setPreloader(false);
				});
		},
		[currentCity, currentBrand, firebase.bd, getFieldsLabels]
	);

	const transliterate = (text) => {
		return text
			.replace(/[А-я]/g, (char) => {
				const letters = {
					А: "A",
					Б: "B",
					В: "V",
					Г: "G",
					Д: "D",
					Е: "E",
					Ж: "Zh",
					З: "Z",
					И: "I",
					Й: "Y",
					К: "K",
					Л: "L",
					М: "M",
					Н: "N",
					О: "O",
					П: "P",
					Р: "R",
					С: "S",
					Т: "T",
					У: "U",
					Ф: "F",
					Х: "Kh",
					Ц: "Ts",
					Ч: "Ch",
					Ш: "Sh",
					Щ: "Sch",
					Ы: "Y",
					Э: "E",
					Ю: "Yu",
					Я: "Ya",
					а: "a",
					б: "b",
					в: "v",
					г: "g",
					д: "d",
					е: "e",
					ж: "zh",
					з: "z",
					и: "i",
					й: "y",
					к: "k",
					л: "l",
					м: "m",
					н: "n",
					о: "o",
					п: "p",
					р: "r",
					с: "s",
					т: "t",
					у: "u",
					ф: "f",
					х: "kh",
					ц: "ts",
					ч: "ch",
					ш: "sh",
					щ: "sch",
					ы: "y",
					э: "e",
					ю: "yu",
					я: "ya",
				};
				return letters[char] || char;
			})
			.replace(/[^a-zA-Z0-9_.-]/g, ""); // Убираем другие символы
	};

	const uploadImage = (file) => {
		return new Promise((resolve, reject) => {
			const uniqueFileName = `${uuidv4()}_${transliterate(file.name)}`;

			const uploadTask = firebase.storage.ref(`${uniqueFileName}`).put(file);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					// Опционально можно отслеживать процесс загрузки
					let progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log("Upload is " + progress + "% done");
				},
				(error) => {
					// Обработка ошибок
					console.error("Upload failed:", error);
					reject(error); // Отклонение промиса при ошибке
				},
				() => {
					// Обработка успешной загрузки
					uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
						console.log("File available at", downloadURL);
						resolve(downloadURL); // Возвращение downloadURL через промис
					});
				}
			);
		});
	};

	const handleDeleteImg = async (img) => {
		const labelIndex = labels.findIndex((item) => item.image === img);

		console.log("ind", labelIndex);

		let data;
		if (labelIndex !== -1) {
			data = {
				...labels[labelIndex],
				image: "",
			};
		}

		const fileName = img.substring(img.lastIndexOf("/") + 1, img.indexOf("?"));

		console.log("fileName", fileName);

		let desertRef = firebase.storage.ref(`${fileName}`);

		desertRef
			.delete()
			.then(() => {})
			.catch((error) => {
				console.log(error);
			});
	};

	const deleteBanner = () => {};

	return (
		<div>
			<Labels
				updateLabel={updateLabel}
				loadStatus={preloader}
				labels={labels}
				changeChecker={changeChecker}
				createLabel={createLabel}
				deleteLabel={deleteLabel}
				uploadImage={uploadImage}
				handleDeleteImg={handleDeleteImg}
				deleteBanner={deleteBanner}
			/>
		</div>
	);
};

export default withFirebase(LabelsContainer);
